import ApproveModal from './ApproveModal';
import RetailProductFilter, { CurrentFilter, CurrentSort } from './RetailProductFilter';
import Table from './Table';
import { RetailProduct } from './types';
import { SetRetailProductsCount } from '../../../models/model_retail_products';
import { getActiveBrand } from '../../../services/api/brandAPI/getActiveBrands';
import { getCannabinoids } from '../../../services/api/productAPI/getCannabinoids';
import { getRetailLocations } from '../../../services/api/retailLocationAPI';
import {
  approveProduct,
  changeStatusRetailProduct,
  getRetailProducts,
  matchRetailProduct,
} from '../../../services/api/retailProducts';
import { RetailProductStatusEnum } from '../../../utils/enum';
import { getStatusColor } from '../../../utils/utils';
import PaginationComponent from '../../product/product_table/components/pagination-component';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Empty, Spin, Image, Button, notification } from 'antd';
import { format, isValid } from 'date-fns';
import { doAction } from 'module-reaction';
import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { CellProps, Column } from 'react-table';

const formatDate = (date: Date | undefined) => {
  if (!!date && isValid(new Date(date))) {
    return (
      <>
        {format(new Date(date), 'MMM d yyyy')} <br /> {format(new Date(date), 'h:mm aaa')}
      </>
    );
  }
  return '';
};

const PreApprovedTable = ({ location: { pathname } }: any) => {
  const collection = pathname.substring(19);
  const [selectedProduct, setSelectedProduct] = useState<RetailProduct | false>(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState<CurrentSort>({ sortBy: '' });
  const [pageSize, setPageSize] = useState(200);
  const [currentFilter, setCurrentFilter] = useState<CurrentFilter>({});
  const [isColumnConfigModalShown, setIsColumnConfigModalShown] = useState(false);
  const [isAddProductModalShown, setIsAddProductModalShown] = useState(false);

  const queryClient = useQueryClient();

  const editModalRef = useRef<HTMLDivElement>(null);

  const brandQuery = useQuery({
    queryKey: ['getActiveBrands'],
    queryFn: getActiveBrand,
    refetchOnWindowFocus: false,
    initialData: [],
  });

  const cannabinoidsQuery = useQuery({
    queryKey: ['getCannabinoids'],
    queryFn: getCannabinoids,
    refetchOnWindowFocus: false,
    initialData: [],
  });

  const getTotals = useCallback(async () => {
    try {
      const mappedTotal = await getRetailProducts({
        limit: 1,
        skip: 0,
        collection: RetailProductStatusEnum.Mapped,
        currentFilter,
        currentSort,
      });

      doAction(SetRetailProductsCount, {
        keyToUpdate: RetailProductStatusEnum.Mapped,
        count: mappedTotal?.total || 0,
      });

      const preMappedTotal = await getRetailProducts({
        limit: 1,
        skip: 0,
        collection: RetailProductStatusEnum.PreMapped,
        currentFilter,
        currentSort,
      });

      doAction(SetRetailProductsCount, {
        keyToUpdate: RetailProductStatusEnum.PreMapped,
        count: preMappedTotal?.total || 0,
      });

      const notMappedTotal = await getRetailProducts({
        limit: 1,
        skip: 0,
        collection: RetailProductStatusEnum.NotMapped,
        currentFilter,
        currentSort,
      });

      doAction(SetRetailProductsCount, {
        keyToUpdate: RetailProductStatusEnum.NotMapped,
        count: notMappedTotal?.total || 0,
      });

      const notSupportedTotal = await getRetailProducts({
        limit: 1,
        skip: 0,
        collection: RetailProductStatusEnum.NotSupported,
        currentFilter,
        currentSort,
      });

      doAction(SetRetailProductsCount, {
        keyToUpdate: RetailProductStatusEnum.NotSupported,
        count: notSupportedTotal?.total || 0,
      });
    } catch (error) {
      console.error('error for the totals');
    }
  }, [currentFilter, currentSort]);

  useEffect(() => {
    setCurrentPage(1);
  }, [collection]);

  const { isLoading, error, data } = useQuery({
    queryKey: ['getRetailProducts', currentPage, pageSize, collection, currentFilter, currentSort],
    queryFn: () =>
      getRetailProducts({
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
        collection,
        currentFilter,
        currentSort,
      }),
    onSuccess: getTotals,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const retailLocationsQuery = useQuery({
    queryKey: ['getRetailLocations'],
    queryFn: () => getRetailLocations({}),
    refetchOnWindowFocus: false,
  });

  const matchMutation = useMutation({
    mutationFn: (updateRetailProduct: {
      id: string;
      retailLocationId: string;
      jointlyProductId: string;
    }) => matchRetailProduct(updateRetailProduct),
    onSuccess: () => {
      notification.success({
        message: 'Product matched successfully',
      });
      queryClient.invalidateQueries({ queryKey: ['getRetailProducts'] });
    },
    onError: (err: any) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong!',
      });
    },
  });

  const changeStatusMutation = useMutation({
    mutationFn: (updateRetailProduct: {
      id: string;
      retailLocationId: string;
      status: RetailProductStatusEnum | null;
    }) => changeStatusRetailProduct(updateRetailProduct),
    onSuccess: () => {
      notification.success({
        message: 'Product unsupported successfully',
      });
      queryClient.invalidateQueries({ queryKey: ['getRetailProducts'] });
    },
    onError: (err: any) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong!',
      });
    },
  });

  const approveProductMutation = useMutation({
    mutationFn: (approveProductDTO: { id: string }) => approveProduct(approveProductDTO),
    onSuccess: (data, variables) => {
      notification.success({ message: 'Success approving' });
      if (selectedProduct) {
        matchMutation.mutate({
          id: selectedProduct?.id,
          retailLocationId: selectedProduct?.retailLocationId,
          jointlyProductId: variables.id,
        });
      }
    },
    onError: (err: any) => {
      notification.error({
        message: err?.response?.data?.message || 'Error approving product!',
      });
    },
  });

  const columns = useMemo<Column<any>[]>(
    () => [
      ...([
        RetailProductStatusEnum.Mapped,
        RetailProductStatusEnum.NotMapped,
        RetailProductStatusEnum.PreMapped,
        RetailProductStatusEnum.NotSupported,
      ].includes(collection)
        ? [
            {
              Header: 'ACTION',
              accessor: 'mainPossibleJointlyMappedId._id',
              disableSortBy: true,
              minWidth: 150,
              Cell: ({ row: { original }, value }: CellProps<any>) => {
                if (collection === RetailProductStatusEnum.NotSupported)
                  return (
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        flexDirection: 'column',
                      }}
                    >
                      <Button
                        type="primary"
                        style={{ width: '100%' }}
                        onClick={() =>
                          changeStatusMutation.mutate({
                            id: original.id,
                            retailLocationId: original.retailLocationId,
                            status: null,
                          })
                        }
                      >
                        Supported
                      </Button>
                    </div>
                  );
                return (
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      flexDirection: 'column',
                    }}
                  >
                    <Button
                      type="primary"
                      style={{
                        width: '100%',
                        background: getStatusColor(original.status),
                        textTransform: 'uppercase',
                        height: 'fit-content',
                        color: 'white',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        border: 'none',
                      }}
                      onClick={() => {
                        setSelectedProduct(original);
                        setOpenApproveModal(true);
                      }}
                    >
                      Search
                    </Button>

                    {collection !== RetailProductStatusEnum.Mapped && (
                      <Button
                        type="primary"
                        style={{ width: '100%' }}
                        onClick={() =>
                          matchMutation.mutate({
                            id: original.id,
                            retailLocationId: original.retailLocationId,
                            jointlyProductId: original.mainPossibleJointlyMappedId._id,
                          })
                        }
                      >
                        Match
                      </Button>
                    )}

                    {collection !== RetailProductStatusEnum.Mapped && (
                      <Button
                        type="primary"
                        style={{ width: '100%' }}
                        danger
                        onClick={() =>
                          changeStatusMutation.mutate({
                            id: original.id,
                            retailLocationId: original.retailLocationId,
                            status: RetailProductStatusEnum.NotSupported,
                          })
                        }
                      >
                        Not supported
                      </Button>
                    )}
                  </div>
                );
              },
            } as Column<any>,
          ]
        : []),
      {
        Header: 'IMAGE',
        width: 150,
        accessor: 'imageUrls',
        Cell: ({ value }: CellProps<any>) => {
          if (!value) return <div className="whiteSquare" />;
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Image src={value} alt="product-list" style={{ objectFit: 'contain' }} height={150} />
            </div>
          );
        },
      },
      ...(collection === RetailProductStatusEnum.Mapped
        ? [
            {
              Header: 'MATCH IMAGE',
              width: 150,
              accessor: 'jointlyProductId.covers',
              Cell: ({ value }: CellProps<any>) => {
                if (!value || !value.length) return <div className="whiteSquare" />;
                const { link } = value[0];
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Image
                      src={link}
                      alt="product-list"
                      style={{ objectFit: 'contain' }}
                      height={150}
                    />
                  </div>
                );
              },
            },
          ]
        : [
            {
              Header: 'MATCH IMAGE',
              width: 150,
              accessor: 'mainPossibleJointlyMappedId.covers',
              Cell: ({ value }: CellProps<any>) => {
                if (!value || !value.length) return <div className="whiteSquare" />;
                const { link } = value[0];
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Image
                      src={link}
                      alt="product-list"
                      style={{ objectFit: 'contain' }}
                      height={150}
                    />
                  </div>
                );
              },
            },
          ]),
      {
        Header: 'NAME / TYPE / SIZE',
        width: 200,
        accessor: 'name',
        Cell: ({ value, row: { original } }: CellProps<any>) => (
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>{value || ''}</div>
            <div style={{ borderBottom: '1px solid #8080806e' }} />
            <div>
              {original?.type?.name} {' /'}
              <br />
              {original?.type?.specific ? original?.type.specific : ''}
            </div>
            <div style={{ borderBottom: '1px solid #8080806e' }} />
            <div>
              [{original?.size?.amount} {original?.size?.unit ? original.size.unit : ''}]
            </div>
          </div>
        ),
      },
      {
        Header: 'AI NAME / TYPE / SIZE',
        width: 200,
        accessor: 'sparkRefinedFields.name',
        Cell: ({ value, row: { original } }: CellProps<any>) => (
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>{value || ''}</div>
            <div style={{ borderBottom: '1px solid #8080806e' }} />
            <div>
              {original?.sparkRefinedFields?.type?.name}
              {' /'}
              <br />
              {original?.sparkRefinedFields?.type?.specific || ''}
            </div>
            <div style={{ borderBottom: '1px solid #8080806e' }} />
            <div>
              [{original?.sparkRefinedFields?.size?.amount}{' '}
              {original?.sparkRefinedFields?.size?.unit
                ? original.sparkRefinedFields?.size.unit
                : ''}
              ]
            </div>
          </div>
        ),
      },
      ...(collection === RetailProductStatusEnum.Mapped
        ? [
            {
              Header: 'MATCH NAME / TYPE',
              width: 200,
              accessor: 'jointlyProductId.name',
              Cell: ({ value, row: { original } }: CellProps<any>) => {
                const type = original?.jointlyProductId?.product_type.type;
                const specific = original?.jointlyProductId?.product_type.specific;
                return (
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>{value || ''}</div>
                    <div
                      style={{
                        borderBottom: '1px solid #8080806e',
                      }}
                    />
                    <div>
                      <span>
                        {type || ''} /<br /> {specific || ''}
                      </span>
                    </div>
                    <div
                      style={{
                        borderBottom: '1px solid #8080806e',
                      }}
                    />
                  </div>
                );
              },
            },
          ]
        : [
            {
              Header: 'MATCH NAME / TYPE / CANNABINOIDS ',
              width: 200,
              accessor: 'mainPossibleJointlyMappedId.name',
              Cell: ({ value, row: { original } }: CellProps<any>) => {
                const type = original?.mainPossibleJointlyMappedId?.product_type.type;
                const specific = original?.mainPossibleJointlyMappedId?.product_type.specific;
                const cannabinoids = original?.mainPossibleJointlyMappedId?.labResults?.filter(
                  (item: any) => item.type == 'cannabinoid' && item.retailLocationId === undefined
                );
                return (
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>{value || ''}</div>
                    <div
                      style={{
                        borderBottom: '1px solid #8080806e',
                      }}
                    />
                    <div>
                      <span>
                        {type || ''} /<br /> {specific || ''}
                      </span>
                    </div>
                    <div
                      style={{
                        borderBottom: '1px solid #8080806e',
                      }}
                    />
                    <div>
                      {cannabinoids?.map((item: any) => (
                        <div key={item._id}>
                          {item.name} {item.min ? `: ${item.min}` : ''}{' '}
                          {item.max > item.min ? ` - ${item.max}` : ''}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              },
            },
          ]),
      {
        Header: 'BRAND',
        width: 200,
        accessor: 'brand',
        Cell: ({ value }: CellProps<any>) => value || '',
      },
      {
        Header: 'AI BRAND',
        width: 200,
        accessor: 'sparkRefinedFields.brand',
        Cell: ({ value }: CellProps<any>) => value || '',
      },
      ...(collection === RetailProductStatusEnum.Mapped
        ? [
            {
              Header: 'MATCH BRAND',
              width: 200,
              accessor: 'jointlyProductId.brand_name',
              Cell: ({ value }: CellProps<any>) => value || '',
            },
          ]
        : [
            {
              Header: 'MATCH BRAND',
              width: 200,
              accessor: 'mainPossibleJointlyMappedId.brand_name',
              Cell: ({ value }: CellProps<any>) => value || '',
            },
          ]),
      {
        Header: 'CREATED',
        accessor: 'createdAt',
        Cell: ({ value }: CellProps<any>) => formatDate(value),
      },
      {
        Header: 'UPDATED',
        accessor: 'updatedAt',
        Cell: ({ value }: CellProps<any>) => formatDate(value),
      },
    ],
    [changeStatusMutation, collection, matchMutation]
  );

  return (
    <div className="tableAndFilterWrapper" ref={editModalRef}>
      <RetailProductFilter
        setCurrentPage={setCurrentPage}
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
        retailLocationList={
          !retailLocationsQuery.isLoading &&
          !retailLocationsQuery.isError &&
          retailLocationsQuery.data &&
          retailLocationsQuery.data &&
          retailLocationsQuery.data.length
            ? retailLocationsQuery.data
            : []
        }
      />
      {isLoading ? (
        <Spin />
      ) : !data || !data.list.length || error ? (
        <div style={{ flex: 10 }}>
          <Empty />
        </div>
      ) : (
        <div className="search-con" style={{ overflow: 'auto', flex: 18 }}>
          <Table
            data={data.list}
            columns={columns}
            setIsColumnConfigModalShown={setIsColumnConfigModalShown}
            isColumnConfigModalShown={isColumnConfigModalShown}
            brandList={brandQuery.data}
            cannabinoidsList={cannabinoidsQuery.data}
            isAddProductModalShown={isAddProductModalShown}
            hideAddProductModal={() => setIsAddProductModalShown(false)}
            selectedProduct={selectedProduct}
            setCurrentSort={setCurrentSort}
            onSuccess={(id) => {
              if (id) approveProductMutation.mutate({ id });
              setOpenApproveModal(false);
            }}
          />
          <br />
          <PaginationComponent
            totalRows={data.total}
            pageChangeHandler={setCurrentPage}
            rowsPerPage={pageSize}
            setPageSize={setPageSize}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setIsColumnConfigModalShown={setIsColumnConfigModalShown}
          />
          <br />
        </div>
      )}

      {openApproveModal && selectedProduct && (
        <ApproveModal
          visible={openApproveModal}
          selectedProduct={selectedProduct}
          onCancel={() => setOpenApproveModal(false)}
          onAddProduct={(retailProduct) => {
            setSelectedProduct(retailProduct);
            setIsAddProductModalShown(true);
            setOpenApproveModal(false);
            setTimeout(() => {
              if (editModalRef.current) {
                const elementToScrollTo = editModalRef.current.querySelector('.editModalWrapper');
                if (elementToScrollTo) {
                  elementToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }
              }
            }, 500);
          }}
        />
      )}
    </div>
  );
};

export default PreApprovedTable;
