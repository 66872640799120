import axios from '../../apiClient';

export const getActiveBrand = async () => {
  const { data } = await axios.get('/brand/admin-search?status=active');
  if (data.list) {
    return data.list.map((brand: { id: string; name: string }) => ({
      id: brand.id,
      name: brand.name,
    }));
  }
  return [];
};
