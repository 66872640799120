import brand from '../../assets/side-bar/brand.svg';
import leftArrow from '../../assets/side-bar/left-arrow.svg';
import products from '../../assets/side-bar/products.svg';
import user from '../../assets/side-bar/user.svg';
import { routes } from '../../utils/enum';
import './sidebar.less';
import {
  BankOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  GiftOutlined,
  BorderBottomOutlined,
  ControlOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { findKey } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

const { Sider } = Layout;

const BuildInfo: React.FC = () => {
  const version = () => process.env.REACT_APP_TAG_VERSION || 'dev';
  const commit_sha = () => {
    if (!process.env.REACT_APP_COMMIT_SHA) {
      return 'N/A';
    }
    return process.env.REACT_APP_COMMIT_SHA.slice(0, 7);
  };

  return (
    <ul className="sidebar-info-style">
      <li className="sidebar-info-item">
        <span className="sidebar-info-title">Release</span>
        {version()}
      </li>
      <li className="sidebar-info-item">
        <span className="sidebar-info-title">Commit</span>
        {commit_sha()}
      </li>
    </ul>
  );
};

const Sidebar: React.FC = () => {
  const history = useHistory();
  const [siderCollapse, setSiderCollapse] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem('userDetails') as string);

  const iconStyle = {
    fontSize: 17,
    color: 'white',
  };

  let menuItems: ItemType[] = [];

  const labelWrapper = (label: string) => <div className="sidebar-menu-item">{label}</div>;

  const adminUserMenuItems: ItemType[] = [
    {
      label: labelWrapper('Product'),
      key: 'productList',
      icon: <img src={products} alt="products" style={iconStyle} />,
    },
    {
      label: labelWrapper('Brand'),
      key: 'brandList',
      icon: <img src={brand} alt="brands" style={iconStyle} />,
    },
    {
      label: labelWrapper('Users'),
      key: 'userList',
      icon: <img src={user} alt="users" style={iconStyle} />,
    },
    {
      label: labelWrapper('Organizations'),
      key: 'organizationList',
      icon: <BankOutlined style={iconStyle} />,
    },
    {
      label: labelWrapper('Retail Locations'),
      key: 'retailLocationList',
      icon: <ShoppingCartOutlined style={iconStyle} />,
    },
    {
      label: labelWrapper('E-commerce'),
      key: 'ecommerceClientList',
      icon: <ShoppingOutlined style={iconStyle} />,
    },
    {
      label: labelWrapper('Retail Users'),
      key: 'retailUserList',
      icon: <img src={user} alt="users" style={iconStyle} />,
    },
    {
      label: labelWrapper('Retail Products'),
      key: 'retailProductList',
      icon: <GiftOutlined style={iconStyle} />,
    },
    {
      label: labelWrapper('Orders'),
      key: 'orderList',
      icon: <BorderBottomOutlined style={iconStyle} />,
    },

    {
      label: labelWrapper('Settings'),
      key: 'settings',
      icon: <ControlOutlined style={iconStyle} />,
    },
  ];

  if (userDetails?.role?.includes('admin_user')) {
    menuItems = adminUserMenuItems;
  } else {
    const retailUserMenuItems = adminUserMenuItems.filter((item: ItemType) =>
      ['retailLocationList', 'orderList'].includes(item?.key as string)
    );
    menuItems = retailUserMenuItems;
  }

  const toggleSider = () => {
    setSiderCollapse(!siderCollapse);
  };

  const onClickSiderMenu = ({ item, key }: any) => {
    history.push(routes[key as keyof typeof routes]);
  };

  return (
    <Sider className="main-sider" trigger={null} collapsible collapsed={siderCollapse} width={220}>
      <div className="sidebar-menu-parent">
        {siderCollapse ? null : <div className="sidebar-menu-text">Menu</div>}
        <Button type="ghost" shape="circle" className="sidebar-expand-button" onClick={toggleSider}>
          <img
            src={leftArrow}
            className="sidebar-left-arrow"
            style={siderCollapse ? { transform: 'rotate(180deg)' } : {}}
            alt="left-arrow"
          />
        </Button>
      </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={[
          findKey(routes, (route) => route === history.location.pathname) as string,
        ]}
        onClick={onClickSiderMenu}
        className="sidebar-menu-style"
        items={menuItems}
        theme="dark"
      />
      <BuildInfo />
    </Sider>
  );
};

export { BuildInfo };
export default Sidebar;
