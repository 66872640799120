import ProductsFilter, { FilterProductBody } from './product-filter';
import { Product } from '../../../../models/model_products_paginated';
import {
  ProductSearchQuery,
  paginatedProducts,
} from '../../../../services/api/productAPI/generalSearchProducts';
import { pendingProductAssociate } from '../../../../services/api/productAPI/pendingProductsAssociate';
import { ProductTypes } from '../../../../utils/options';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal, Spin, notification } from 'antd';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import './AssociateProductsModal.less';

type Props = {
  showAssociateModal: boolean;
  setShowAssociateModal: (show: boolean) => void;
  selectedProduct: Product | false;
  setSelectedProduct: (product: Product | false) => void;
  brandList: {
    id: string;
    name: string;
  }[];
};

const emptyFormValues = {
  brand: '',
  type: '',
  specific: '',
  search_key: '',
  states: '',
  store_url: '',
  last_updated_by: '',
  status: 'active',
  pageSize: 20,
  pageIdx: 1,
};

const AssociateProductsModal = ({
  showAssociateModal,
  setShowAssociateModal,
  selectedProduct,
  setSelectedProduct,
  brandList,
}: Props) => {
  const [existingProduct, setExistingProduct] = useState<{ name: string; id: string } | false>(
    false
  );
  const [formValues, setFormValues] = useState<ProductSearchQuery>(emptyFormValues);

  const queryClient = useQueryClient();

  const associateProductMutation = useMutation({
    mutationFn: pendingProductAssociate,
    onSuccess: () => {
      notification.success({
        message: 'Product associated successfully!',
      });
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['paginatedProducts'] });
      }, 500);
      setSelectedProduct(false);
      setExistingProduct(false);
      setShowAssociateModal(false);
    },
    onError: (err: AxiosError) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong!',
      });
    },
  });

  const getProductsToAssociateQuery = useQuery({
    queryKey: ['getProductsToAssociate', formValues],
    queryFn: () =>
      paginatedProducts({
        search_key: formValues.search_key,
        states: formValues.states,
        store_url: formValues.store_url,
        brand: formValues.brand,
        product_type: formValues.product_type,
        productSpecific: formValues.productSpecific,
        last_updated_by: formValues.last_updated_by,
        reviewRequired: formValues.reviewRequired,
        status: 'active',
        pageSize: 20,
        pageIdx: 1,
      }),
    onError: () => {
      notification.error({
        message: 'Something went wrong',
        description: 'Please try later',
      });
    },
    initialData: {
      list: [],
      total: 0,
    },
    enabled: JSON.stringify(formValues) !== JSON.stringify(emptyFormValues),
  });

  return (
    <Modal
      open={showAssociateModal}
      title={'Associate with existing product'}
      onOk={() => {
        if (!selectedProduct || !existingProduct) {
          return notification['error']({
            message: 'Please select a product to associate!',
          });
        }
        associateProductMutation.mutate({
          currentId: selectedProduct.id,
          existingId: existingProduct.id,
        });
      }}
      onCancel={() => {
        setSelectedProduct(false);
        setExistingProduct(false);
        setShowAssociateModal(false);
      }}
      confirmLoading={associateProductMutation.isLoading}
      width="90vw"
      okButtonProps={{ style: !existingProduct ? { display: 'none' } : {} }}
    >
      <ProductsFilter
        onFilter={(form: FilterProductBody) => {
          setFormValues({
            brand: form.brand,
            product_type: form.product_type || '',
            productSpecific: form.productSpecific || '',
            search_key: form.search_key,
            states: form.states,
            store_url: form.store_url,
            last_updated_by: form.last_updated_by,
            reviewRequired: form.reviewRequired,
            status: 'active',
            pageSize: 20,
            pageIdx: 1,
          });
        }}
        brandList={brandList}
        activateBulkActions={() => null}
        collection="approved"
      />
      <div className="associate-list">
        {getProductsToAssociateQuery.isLoading && (
          <div className="pending-loading">
            <Spin size="large" />
          </div>
        )}
        {!!getProductsToAssociateQuery?.data?.list?.length &&
          getProductsToAssociateQuery.data.list.map(
            ({ name, brand_name: brandName, id, covers, product_type: { type } }: Product) => (
              <div
                className={`associate-item ${
                  !!existingProduct && id === existingProduct.id ? 'selected' : ''
                }`}
                key={id}
                onClick={() => {
                  setExistingProduct({ name, id });
                }}
                data-testid="possibleAssociate"
              >
                <img src={covers[0]?.link} className="associate-item-image" alt="product-list" />
                {name}
                <b> - {brandName}</b> - {ProductTypes.find((item) => item.key === type)?.name}
              </div>
            )
          )}
      </div>
    </Modal>
  );
};

export default AssociateProductsModal;
