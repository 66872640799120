import {
  createEcommerceClient,
  EcommerceClient,
  updateEcommerceClient,
} from '../../services/api/ecommerceClient';
import { getRetailLocations } from '../../services/api/retailLocationAPI';
import { getSetting } from '../../services/api/settings';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Input, Modal, notification, Select } from 'antd';
import { AxiosError } from 'axios';
import React, { useMemo, useState } from 'react';
const { Option } = Select;

type Props = {
  setOpen: (value: boolean) => void;
  itemToEdit?: EcommerceClient;
};

export const promptsPlaceholders = [
  'Flower to relax',
  'Edibles to energize',
  'Highest THC Flower',
  'Concentrates for intimacy',
  'Tinctures for pain',
  'Vapes for everyday stress',
  'High CBD topicals',
  'Drinks for socializing',
];

const connectionInfoDictionary: Record<string, string> = {
  publicKey: 'Public key',
  retailerId: 'Retailer ID',
  storeId: 'Store ID',
};

const AddEditModal = ({ setOpen, itemToEdit }: Props) => {
  const isEditMode = !!itemToEdit;
  const [eCommerceClientType, setECommerceClientType] = useState<string | null>(
    itemToEdit?.type || null
  );
  const [connectionInfo, setConnectionInfo] = useState<Record<string, string | number>>(
    itemToEdit?.connectionInfo || {}
  );

  const [form] = Form.useForm();

  const retailLocationsQuery = useQuery({
    queryKey: ['getRetailLocations'],
    queryFn: () => getRetailLocations({}),
    refetchOnWindowFocus: false,
  });

  const eCommerceClientTypesQuery = useQuery({
    queryKey: ['getSettingECommerceType'],
    queryFn: () => getSetting('eCommerceClientTypes'),
    onError: () => {
      notification.error({
        message: 'Something went wrong!',
        description: 'Unable to get eCommerce types',
      });
    },
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();

  const addEditClient = useMutation({
    mutationFn: isEditMode ? updateEcommerceClient : createEcommerceClient,
    onSuccess: ({ data }) => {
      notification.success({
        message: `Client ${data.host} ${isEditMode ? 'updated' : 'added'} successfully!`,
      });
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['getEcommerceClients'] });
      }, 500);
      form.resetFields();
      setOpen(false);
    },
    onError: (err: AxiosError) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong!',
      });
    },
  });

  const onFinish = (values: EcommerceClient) => {
    addEditClient.mutate({
      ...values,
      connectionInfo,
      ...(itemToEdit?._id && { _id: itemToEdit?._id }),
      prompts: values.prompts.filter((i: string) => i !== ''),
    });
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const eCommerceClientConnectionInfoFields = useMemo(
    () =>
      (!!eCommerceClientType &&
        eCommerceClientTypesQuery.data?.value?.find(
          (eCommerceClient: { type: string }) => eCommerceClient.type === eCommerceClientType
        ) &&
        eCommerceClientTypesQuery.data?.value?.find(
          (eCommerceClient: { type: string; requiredFields: string[] }) =>
            eCommerceClient.type === eCommerceClientType
        )?.requiredFields) ||
      [],
    [eCommerceClientType, eCommerceClientTypesQuery]
  );

  return (
    <>
      <Modal
        title={isEditMode ? 'Edit Client' : 'Add Client'}
        open={true}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          initialValues={
            isEditMode
              ? {
                  ...itemToEdit,
                  prompts:
                    itemToEdit?.prompts && itemToEdit.prompts.length
                      ? promptsPlaceholders.map((i, index) => itemToEdit.prompts[index] || '')
                      : promptsPlaceholders.map(() => ''),
                }
              : { prompts: promptsPlaceholders.map(() => '') }
          }
        >
          <Form.Item<EcommerceClient>
            label="Host"
            name="host"
            validateTrigger={['onBlur']}
            rules={[{ required: true, message: 'Please input the host', whitespace: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="retailLocationId" label="Retail Location" rules={[{ required: true }]}>
            <Select allowClear loading={retailLocationsQuery.isLoading}>
              {retailLocationsQuery.data &&
                retailLocationsQuery.data
                  .sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    return 1;
                  })
                  .map(({ name, id }: { name: string; id: string }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
          <Form.Item<EcommerceClient>
            label="AI Title"
            name="aiTitle"
            validateTrigger={['onBlur']}
            rules={[{ message: 'Please input the ai title', type: 'string', whitespace: true }]}
          >
            <Input placeholder="Budtender Intelligence" />
          </Form.Item>
          <Form.Item<EcommerceClient>
            label="Welcome Text"
            name="welcomeText"
            validateTrigger={['onBlur']}
            rules={[{ message: 'What are you looking for?', type: 'string', whitespace: true }]}
          >
            <Input placeholder="Budtender Intelligence" />
          </Form.Item>
          <Form.Item<EcommerceClient>
            label="Path To Product"
            name="pathToProductId"
            validateTrigger={['onBlur']}
            rules={[{ message: 'Enter a valid URL', type: 'url', whitespace: true }]}
          >
            <Input />
          </Form.Item>
          <Form.List name="prompts">
            {(fields) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item label={index === 0 ? 'Prompts' : ''} required={false} key={field.key}>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          whitespace: true,
                          type: 'string',
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder={promptsPlaceholders[index]} style={{ width: '90%' }} />
                    </Form.Item>
                  </Form.Item>
                ))}
              </>
            )}
          </Form.List>
          <Form.Item name="type" label="Type" rules={[{ required: true }]}>
            <Select
              allowClear
              onChange={(value) => {
                if (eCommerceClientType) {
                  setConnectionInfo({});
                }

                setECommerceClientType(value);
              }}
            >
              <Option key="dutchiePlus" value="dutchiePlus">
                Dutchie Plus
              </Option>
              <Option key="tymber" value="tymber">
                Tymber
              </Option>
            </Select>
          </Form.Item>
          {eCommerceClientConnectionInfoFields.map((field: string) => (
            <div key={field} style={{ marginLeft: 20 }}>
              <Form.Item
                label={connectionInfoDictionary[field]}
                name={field}
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue={connectionInfo[field]}
              >
                <Input
                  onChange={(e) => {
                    setConnectionInfo((prevState) => ({
                      ...prevState,
                      [field]: e.target.value,
                    }));
                  }}
                  defaultValue={connectionInfo[field] || ''}
                />
              </Form.Item>
            </div>
          ))}
        </Form>
      </Modal>
    </>
  );
};

export default AddEditModal;
