import logo from '../../assets/logo_sm.svg';
import { MODULE_USER } from '../../models/model_user';
import { routes } from '../../utils/enum';
import { Net } from '../../utils/utils';
import { Layout, Col, Form, Input, Button, message } from 'antd';
import { doFunction, doAction } from 'module-reaction';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './user_signin.less';
const { Header, Content } = Layout;

type UserSigninProp = RouteComponentProps;
export class UserSigninView extends React.Component<UserSigninProp> {
  state = { password: '', email: '' };

  render() {
    return (
      <Layout className="signin-view">
        <Header>
          <img src={logo} alt="Jointly" />
        </Header>
        <Content>
          <Col lg={8} md={10} sm={20} xs={22}>
            {
              <div className="content-box">
                <div className="title">JOINTLY ADMIN LOGIN</div>
                <Col className="form-box" lg={{ span: 22, offset: 1 }} sm={{ span: 24 }}>
                  <Form className="password-box" onFinish={this.onSubmit} layout="vertical">
                    <Form.Item label="Email">
                      <Input type="email" onChange={(e) => this.recEmail(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Password">
                      <Input type="password" onChange={(e) => this.recPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                      <Button type="primary" className="submit-btn" htmlType="submit">
                        SIGN IN
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </div>
            }
          </Col>
        </Content>
      </Layout>
    );
  }

  private recPassword(password: string) {
    this.setState({ password });
  }
  private recEmail(email: string) {
    this.setState({ email });
  }

  private onSubmit = async () => {
    const { password, email } = this.state;
    if (!password) {
      message.error('please fill password');
      return;
    }
    if (!email) {
      message.error('please fill email');
      return;
    }

    const res = await Net.req('/user/admin-sign-in', { email, password }, 'post');
    if (res && res.jwt_token) {
      // after log in, goto default page
      doAction(MODULE_USER, { logined: true });
      localStorage.setItem('userDetails', JSON.stringify(res.user));

      doFunction(async () => {
        if (res.user.role.includes('admin_user')) {
          this.props.history.replace(routes.productList);
        } else {
          this.props.history.replace(routes.retailLocationList);
        }
      });
    }
  };
}
