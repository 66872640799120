import apiClient from '../../services/apiClient';
import { CameraOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Skeleton, Tooltip, message } from 'antd';
import axios from 'axios';
import React, { useState, useCallback, MouseEvent } from 'react';
import { useDropzone, DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';

enum ImageUploaderState {
  Initial,
  Uploading,
  Showing,
}

enum ImageUploaderEvent {
  Upload,
  Uploaded,
  Clear,
}

const useImageUploaderStateMachine = (uploadedImage: string | undefined) => {
  const [state, setState] = useState<ImageUploaderState>(
    uploadedImage ? ImageUploaderState.Showing : ImageUploaderState.Initial
  );

  const fireEvent = (event: ImageUploaderEvent) => {
    setState((currentState) => {
      switch (event) {
        case ImageUploaderEvent.Upload:
          if (currentState === ImageUploaderState.Initial) return ImageUploaderState.Uploading;
          break;
        case ImageUploaderEvent.Uploaded:
          if (currentState === ImageUploaderState.Uploading) return ImageUploaderState.Showing;
          break;
        case ImageUploaderEvent.Clear:
          if (currentState === ImageUploaderState.Showing) return ImageUploaderState.Initial;
          else if (currentState === ImageUploaderState.Uploading) return ImageUploaderState.Initial;
          break;
      }
      return currentState;
    });
  };

  return [state, fireEvent] as const;
};

type ImageUploader = {
  url?: string;
  onChangeUrl?: (url: string) => void;
  label?: string;
  format?: 'square' | 'rectangle';
  retailLocationId?: string;
};

type ImageUploadResponse = {
  files: {
    fileName: string;
    uploadUrl: string;
  }[];
};

/**
 * For now it is coupled with the retailLocation form, but the image uploader can be abstracted in the future
 */
const ImageUploader: React.FC<ImageUploader> = ({
  url = undefined,
  onChangeUrl,
  label,
  format = 'square',
  retailLocationId,
}) => {
  const [uploadedImage, setUploadedImage] = useState<string | undefined>(url);
  const [hover, setHover] = useState(false);

  const [state, fireEvent] = useImageUploaderStateMachine(uploadedImage);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      fireEvent(ImageUploaderEvent.Upload);
      const file = acceptedFiles[0];

      try {
        const response = await apiClient.post<ImageUploadResponse>(
          `org/v1/location/${retailLocationId}/upload`,
          {
            files: [
              {
                fileName: file.name,
                contentType: file.type,
              },
            ],
          }
        );

        const url = response.data.files[0].uploadUrl;

        //For some reason axios throws a 400 on this request... so we use fetch
        const res = await fetch(url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        });

        if (res.status !== 200) throw new Error(`Error uploading image: ${res.statusText}`);

        const strippedUrl = url.split('?')[0];

        onChangeUrl?.(strippedUrl);
        setUploadedImage(strippedUrl);
        fireEvent(ImageUploaderEvent.Uploaded);
      } catch (error) {
        console.error('Error uploading image:', error);
        if (axios.isAxiosError(error) && error.response?.data?.message) {
          message.error(`Error uploading image: ${error.response.statusText}`);
        } else message.error(error);
        fireEvent(ImageUploaderEvent.Clear);
      }
    },
    [onChangeUrl, retailLocationId, fireEvent]
  );

  const handleClear = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setUploadedImage(undefined);
    onChangeUrl?.('');
    fireEvent(ImageUploaderEvent.Clear);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
  });

  const rootProps: DropzoneRootProps = getRootProps();
  const inputProps: DropzoneInputProps = getInputProps();

  return (
    <div {...rootProps}>
      <input {...inputProps} />
      {label && <p>{label}</p>}

      <div
        data-testid="dropzone"
        style={{
          width: format === 'rectangle' ? '100%' : '100px',
          height: '100px',
          border: `2px ${!uploadedImage ? 'dashed' : 'solid'} ${isDragActive ? '#5344ff' : '#ccc'}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          padding: '4px',
          borderRadius: '4px',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {(state === ImageUploaderState.Initial || hover) && (
          <Tooltip title="Drag 'n' drop an image here, or click to select an image">
            <CameraOutlined
              data-testid="camera-icon"
              style={{
                fontSize: 48,
                color: '#ccc',
                position: 'absolute',
                filter: 'drop-shadow(0px 0px 2px rgb(0 0 0 / 0.5))',
              }}
            />
          </Tooltip>
        )}
        {state === ImageUploaderState.Showing && hover && (
          <Tooltip title="Clear Image">
            <Button
              data-testid="clear-image-button"
              icon={
                <CloseOutlined
                  style={{
                    filter: 'drop-shadow(0px 0px 1px #FFF)',
                  }}
                />
              }
              type="text"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={handleClear}
            />
          </Tooltip>
        )}
        {state === ImageUploaderState.Uploading && (
          <Skeleton.Image active data-testid="image-skeleton" />
        )}
        {state === ImageUploaderState.Showing && (
          <img
            data-testid="uploaded-image"
            src={uploadedImage}
            alt="Uploaded"
            style={{
              objectFit: 'contain',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ImageUploader;
