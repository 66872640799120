import PossibleMatchesTable from './PossibleMatchesTable';
import RetailProductComponent from './RetailProductComponent';
import {
  generalSeachProducts,
  getBrandList,
} from '../../../services/api/productAPI/generalSearchProducts';
import { matchRetailProduct } from '../../../services/api/retailProducts';
import { RetailProductStatusEnum } from '../../../utils/enum';
import ProductFilter, {
  FilterProductBody,
} from '../../product/product_table/components/product-filter';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal, Spin, Empty, Button, notification } from 'antd';
import React, { useState } from 'react';

type Props = {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  height?: number;
  unmatchedProduct: any;
  retailProductInfo: {
    reference: any;
    currentStatus: RetailProductStatusEnum;
    name: string;
    brand: string;
    size: {
      amount: string;
      unit?: string;
    };
    metadata: any;
    imageUrls: string;
  };
};

interface Form {
  search_key?: string;
  brand_name?: string;
  type?: string;
  specific?: string;
  states?: string;
  store_url?: string;
  brand?: string;
}

const SearchMapProductModal = ({
  visible,
  onOk,
  onCancel,
  height,
  unmatchedProduct,
  retailProductInfo,
}: Props) => {
  const [form, setForm] = useState<Form>({});
  const [selectedMatch, setSelectedMatch] = useState<any>(null);

  const queryClient = useQueryClient();

  const searchProductList = (form: FilterProductBody) => {
    const { brand_name, product_type, productSpecific, search_key, states, store_url, brand } =
      form;

    const preForm = {
      ...(search_key ? { search_key } : {}),
      ...(brand_name ? { brand_name } : {}),
      ...(brand ? { brand } : {}),
      ...(product_type ? { type: product_type } : {}),
      ...(productSpecific ? { specific: productSpecific } : {}),
      ...(states ? { states } : {}),
      ...(store_url ? { store_url } : {}),
    };
    if (Object.keys(preForm).length > 0) {
      setForm(preForm);
    } else {
      setForm({});
    }
  };

  const { isLoading, error, data, fetchStatus } = useQuery({
    queryKey: ['getProducts', form],
    queryFn: () => generalSeachProducts(form),
    enabled: !!Object.keys(form).length,
  });

  const brandQuery = useQuery({
    queryKey: ['getBrands'],
    queryFn: () => getBrandList(),
  });

  const matchMutation = useMutation({
    mutationFn: (updateRetailProduct: {
      id: string;
      retailLocationId: string;
      jointlyProductId: string;
    }) => matchRetailProduct(updateRetailProduct),
    onSuccess: () => {
      onOk();
      queryClient.invalidateQueries({ queryKey: ['getRetailProducts'] });
    },
    onError: (err: any) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong!',
      });
    },
  });

  return (
    <Modal
      visible={visible}
      title={'Find the match'}
      onCancel={onCancel}
      width="90vw"
      footer={[
        ...(selectedMatch
          ? [
              <Button
                type="primary"
                key="match"
                loading={matchMutation.isLoading}
                onClick={() => {
                  matchMutation.mutate({
                    id: unmatchedProduct.id,
                    retailLocationId: unmatchedProduct.retailLocationId,
                    jointlyProductId: selectedMatch.id,
                  });
                }}
              >
                Match
              </Button>,
            ]
          : []),
        <Button key="back" onClick={onCancel}>
          Return
        </Button>,
      ]}
    >
      <div
        style={{
          width: '100%',
          overflow: 'auto',
          display: 'flex',
        }}
      >
        <RetailProductComponent {...retailProductInfo} />
        <div
          style={{
            flex: 0.4,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div style={{ height: '90%', width: 2, background: 'gray' }} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 12,
            overflow: 'auto',
          }}
        >
          <div>
            <ProductFilter
              onFilter={(form: FilterProductBody) => searchProductList(form)}
              brandList={brandQuery?.data || []}
              activateBulkActions={() => null}
              currentFilter={{
                search_key: form?.search_key,
                brand_name: form.brand_name,
                brand: form.brand,
                product_type: form.type,
                productSpecific: form.specific,
                states: form.states,
                store_url: form.store_url,
              }}
              collection={'active'}
              setCurrentPage={() => true}
              approvedMode={true}
              defaultBrand={retailProductInfo.brand}
              defaultProductType={retailProductInfo?.metadata?.type?.name}
            />
          </div>

          {isLoading && fetchStatus !== 'idle' ? (
            <div>
              <Spin />
            </div>
          ) : error || !data ? (
            <div style={{ flex: 10 }}>
              <Empty />
            </div>
          ) : (
            <div
              style={{
                maxHeight: (height || 800) - 50,
                overflow: 'auto',
              }}
            >
              <PossibleMatchesTable
                possibleMatchingProducts={data}
                selectedMatch={selectedMatch}
                setSelectedMatch={setSelectedMatch}
                idField="id"
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SearchMapProductModal;
