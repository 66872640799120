import { Typography, Select, Form } from 'antd';
import React from 'react';
import './multiple-select-component.less';

const { Option } = Select;

interface TextInputProps {
  placeholder: string;
  title: string;
  name: string;
  options: any;
  valueItem: string;
  keyItem: string;
  multiple: boolean;
  size: string;
  required: boolean;
}

const MultipleSelectComponent = ({
  placeholder,
  size,
  multiple,
  title,
  options,
  keyItem,
  valueItem,
  name,
  required,
}: TextInputProps) => (
  <div data-testid="select-container" className="select-container-1" style={{ width: size }}>
    <Typography.Text>{title}</Typography.Text>
    <Form.Item name={name} rules={[{ required: required }]}>
      <Select
        showSearch
        style={{ width: size }}
        placeholder={placeholder}
        optionFilterProp="children"
        mode={multiple ? 'multiple' : undefined}
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option key="all" value="" disabled={multiple}>
          {title}
        </Option>
        {options.map((item: any) => (
          <Option key={item[keyItem]} value={item[keyItem]}>
            {item[valueItem]}
          </Option>
        ))}
      </Select>
    </Form.Item>
  </div>
);

export default MultipleSelectComponent;
