import ColumnsConfig from './column-config';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import './table.less';
import {
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useExpanded,
  useBlockLayout,
  useResizeColumns,
} from 'react-table';

type Props = {
  data: any[];
  columns: any[];
  selectedItem: any;
  isEditModalShown: boolean;
  setMultiSelectType: Function;
  setSelectedItens: Function;
  setIsColumnConfigModalShown: Function;
  isColumnConfigModalShown: boolean;
  editModal?: any;
};

const ReactTable = ({
  data,
  columns,
  selectedItem,
  isEditModalShown,
  setMultiSelectType,
  setSelectedItens,
  setIsColumnConfigModalShown,
  isColumnConfigModalShown,
  editModal,
}: Props) => {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 100,
      maxWidth: 400,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    visibleColumns,
    selectedFlatRows,
    isAllRowsSelected,
    setHiddenColumns,
    allColumns,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      defaultColumn,
      manualSortBy: true,
      initialState: {
        sortBy: [
          {
            id: 'createdAt',
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    useBlockLayout,
    useResizeColumns,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (isAllRowsSelected) {
      setMultiSelectType('whole');
    } else if (Object.keys(selectedFlatRows).length) {
      setMultiSelectType('multi');
    } else {
      setMultiSelectType('none');
    }
    setSelectedItens(selectedFlatRows.map((item) => item.original));
  }, [selectedFlatRows, setSelectedItens, isAllRowsSelected]);

  const renderRowSubComponent = React.useCallback(
    ({ row }: any) => (
      <pre
        style={{
          fontSize: '10px',
        }}
      >
        <code>{JSON.stringify({ values: row.values }, null, 2)}</code>
      </pre>
    ),
    []
  );

  return (
    <>
      <table {...getTableProps()}>
        <thead className="sticky">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th
                  className="colunmHeader"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  {column.render('Header')}

                  {!['selection', 'action'].includes(column.id) && (
                    <>
                      {column.canSort && (
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <CaretDownOutlined
                                className="sortIndicator"
                                style={{
                                  color: '#d558ff',
                                }}
                              />
                            ) : (
                              <CaretUpOutlined
                                className="sortIndicator"
                                style={{
                                  color: '#d558ff',
                                }}
                              />
                            )
                          ) : (
                            <CaretDownOutlined
                              className="sortIndicator"
                              style={{
                                color: '#b5b5b5',
                              }}
                            />
                          )}
                        </span>
                      )}

                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                      />
                    </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, index) => {
            prepareRow(row);
            return (
              <React.Fragment key={index}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any, index: number) => (
                    <td {...cell.getCellProps()} key={index}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
                {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                  </tr>
                )}
                {row.original?.id === selectedItem?.id && isEditModalShown && (
                  <tr>
                    <td className="editModalWrapper" colSpan={visibleColumns.length}>
                      {editModal}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {/* Column configuration */}
      <ColumnsConfig
        visible={isColumnConfigModalShown}
        onCancel={() => setIsColumnConfigModalShown(false)}
        colums={allColumns}
        setHiddenColumns={setHiddenColumns}
      />
    </>
  );
};

export default ReactTable;
