import { Brand } from '../../../models/model_brand';
import { Product } from '../../../models/model_product';
import { ProductFilter } from '../../../models/model_products_paginated';
import { Net, encodeQuery } from '../../../utils/utils';
import axios from '../../apiClient';

export interface ProductSearchQuery extends ProductFilter {
  status: string;
  pageSize: number;
  pageIdx: number;
  sort_by?: string;
  order?: 'desc' | 'asc';
}

interface BrandItem {
  id: string;
  name: string;
}

export const generalSeachProducts = async ({
  search_key,
  brand_name,
  brand,
  type,
  specific,
  states,
  store_url,
}: {
  search_key?: string;
  brand_name?: string;
  brand?: string;
  type?: string;
  specific?: string;
  states?: string;
  store_url?: string;
}): Promise<Product[]> => {
  const { data } = await axios.post('/option/product/admin-search', {
    search_key,
    brand_name,
    brand,
    type,
    specific,
    states,
    store_url,
  });
  return data;
};

export const getBrandList = async (): Promise<Brand[]> => {
  const res = await Net.req('/brand/admin-search', {
    status: 'active',
  });

  return res.list.map((brand: BrandItem) => ({
    id: brand.id,
    name: brand.name,
  }));
};

export const paginatedSearchAdminUsers = async (
  query: any
): Promise<{ _id: string; user: string }[]> => {
  delete query.last_updated_by;
  const url = encodeQuery('/product/paginated-search-admin-users', query);
  const { data } = await axios.get(url);
  return data;
};

export const paginatedProducts = async ({
  pageSize,
  pageIdx,
  sort_by,
  order,
  search_key,
  product_type,
  productSpecific,
  brand_name,
  last_updated_by,
  brand,
  states,
  store_url,
  reviewRequired,
  status,
}: ProductSearchQuery) => {
  const url = encodeQuery('/product/paginated-search', {
    limit: pageSize,
    skip: pageSize * (pageIdx - 1),
    ...(!!sort_by && { sort_by }),
    ...(!!order && { order }),
    query: JSON.stringify({
      status: status === 'approved' ? 'active' : status,
      ...(!!search_key && { search_key }),
      ...(!!product_type && { type: product_type }),
      ...(!!productSpecific && { specific: productSpecific }),
      ...(!!brand_name && { brand_name }),
      ...(!!last_updated_by && { last_updated_by }),
      ...(!!brand && { brand }),
      ...(!!states && { states }),
      ...(!!store_url && { store_url }),
      ...(reviewRequired !== undefined && {
        reviewRequired: !!reviewRequired,
      }),
    }),
  });
  const { data } = await axios.get(url);
  return data;
};
