import AddEditModal from './AddEditModal';
import {
  deleteEcommerceClient,
  EcommerceClient,
  getEcommerceClients,
} from '../../services/api/ecommerceClient';
import { formatDate } from '../../utils/utils';
import { CopyOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Button, notification, Popconfirm, Space, Table } from 'antd';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ecommerceClient.less';

const { Column } = Table;

const EcommerceClientList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<EcommerceClient | undefined>();
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    if (!isModalOpen) {
      setItemToEdit(undefined);
    }
  }, [isModalOpen]);

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['getEcommerceClients', { skip, limit: 20 }],
    queryFn: getEcommerceClients,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    initialData: { results: [], total: 0 },
    retry: 1,
    onError: (err: AxiosError) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong!',
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteEcommerceClient,
    onSuccess: ({ data }) => {
      notification.success({
        message: `Client ${data.host} deleted successfully!`,
      });
      setTimeout(() => {
        refetch();
      }, 500);
    },
    onError: (err: AxiosError) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong!',
      });
    },
  });

  return (
    <div className="ecommerce-wrapper">
      <Space size="middle" className="ecommerce-header-wrapper">
        <div className="ecommerce-client-list-header">Ecommerce Clients</div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          Add
        </Button>
      </Space>
      <Table
        dataSource={data.results.map((item) => ({ ...item, key: item._id }))}
        pagination={{
          pageSize: 20,
          total: data.total,
          position: ['bottomCenter'],
          onChange: (page: number) => {
            setSkip((page - 1) * 5);
          },
        }}
        loading={isLoading}
      >
        <Column
          title="Action"
          key="action"
          render={(_: undefined, record: EcommerceClient) => (
            <Space size="middle">
              <Button
                type="primary"
                icon={<CopyOutlined />}
                onClick={() => {
                  const scriptTag = `<script src="${process.env.REACT_APP_SPARK_BASE_URL}/api/v1/spark/chat?client_id=${record._id}" type="text/javascript"></script>`;
                  navigator.clipboard.writeText(scriptTag);
                  notification.success({
                    message: `Script tag copied to clipboard!`,
                  });
                }}
              >
                Script tag
              </Button>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => {
                  setItemToEdit(record);
                  setTimeout(() => {
                    setIsModalOpen(true);
                  }, 100);
                }}
              >
                Edit
              </Button>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => {
                  if (record._id) deleteMutation.mutate(record._id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" danger icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
        <Column
          title="Allowed Hosts"
          dataIndex="host"
          key="host"
          render={(value: string) => (
            <a href={value} target="_blank" rel="noreferrer">
              {value}
            </a>
          )}
        />
        <Column
          title="Retail Location ID"
          dataIndex="retailLocationId"
          key="retailLocationId"
          render={(value: string) => <Link to={'retailLocation/' + value}>{value}</Link>}
        />
        <Column
          title="Created At"
          dataIndex="createdAt"
          key="createdAt"
          render={(value: Date) => formatDate(value)}
        />
        <Column
          title="Updated At"
          dataIndex="updatedAt"
          key="updatedAt"
          render={(value: Date) => formatDate(value)}
        />
      </Table>
      {isModalOpen && <AddEditModal setOpen={setIsModalOpen} itemToEdit={itemToEdit} />}
    </div>
  );
};

export default EcommerceClientList;
