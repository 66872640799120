import axios from '../../apiClient';
import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

export type EcommerceClient = {
  _id?: string;
  host: string;
  retailLocationId: string;
  aiTitle: string;
  welcomeText: string;
  pathToProductId: string;
  prompts: string[];
  type: 'dutchiePlus' | 'tymber';
  connectionInfo: Record<string, string | number>;
  createdAt: Date;
  updatedAt: Date;
};

export const createEcommerceClient = (
  data: EcommerceClient
): Promise<AxiosResponse<EcommerceClient>> => axios.post('/ecommerceClient', data);

export const updateEcommerceClient = (
  data: EcommerceClient
): Promise<AxiosResponse<EcommerceClient>> => axios.put(`/ecommerceClient/${data._id}`, data);

export const deleteEcommerceClient = (id: string): Promise<AxiosResponse<EcommerceClient>> =>
  axios.delete(`/ecommerceClient/${id}`);

export const getEcommerceClients = async ({
  queryKey,
}: QueryFunctionContext<[string, { skip: number; limit: number }]>): Promise<{
  results: EcommerceClient[];
  total: number;
}> => {
  const [, { skip, limit }] = queryKey;
  const { data } = await axios.get(`/ecommerceClient?skip=${skip}&limit=${limit}`);
  return data;
};
